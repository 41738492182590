import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { CourierComponent } from './courier/courier.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { BarComponent } from './bar/bar.component';
import { StackbarComponent } from './stackbar/stackbar.component';
import { MapaColoniasComponent } from './mapa-colonias/mapa-colonias.component';
// import { SplinebarComponent } from './splinebar/splinebar.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { AreaChartComponent } from './area-chart/area-chart.component';
import { MapaAlcaldiasComponent } from './mapa-alcaldias/mapa-alcaldias.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { MapaSemujeresComponent } from './mapa-semujeres/mapa-semujeres.component';
import { MapOpenDataComponent } from './map-open-data/map-open-data.component';
import { MapControlComponent } from './map-control/map-control.component';
import { MapParkComponent } from './map-park/map-park.component';
import { MapParkPointsComponent } from './map-park-points/map-park-points.component';
import { MapaRetoVerdeComponent } from './mapa-reto-verde/mapa-reto-verde.component';
import { MapaInfraSocialComponent } from './mapa-infra-social/mapa-infra-social.component';
import { DashboardStackComponent } from './dashboard-stack/dashboard-stack.component';
import { MapaCaptacionAguaComponent } from './mapa-captacion-agua/mapa-captacion-agua.component';

@NgModule({
  declarations: [
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    CourierComponent,
    NotFoundComponent,
    BarComponent,
    StackbarComponent,
    MapaColoniasComponent,
    PieChartComponent,
    AreaChartComponent,
    MapaAlcaldiasComponent,
    LineChartComponent,
    BarChartComponent,
    MapaSemujeresComponent,
    MapOpenDataComponent,
    MapControlComponent,
    MapParkComponent,
    MapParkPointsComponent,
    MapaRetoVerdeComponent,
    MapaInfraSocialComponent,
    DashboardStackComponent,
    MapaCaptacionAguaComponent
  ],
  exports: [
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    CourierComponent,
    NotFoundComponent,
    BarComponent,
    MapaColoniasComponent,
    StackbarComponent,
    PieChartComponent,
    AreaChartComponent,
    MapaAlcaldiasComponent,
    MapaSemujeresComponent,
    MapOpenDataComponent,
    MapControlComponent,
    MapParkComponent,
    MapParkPointsComponent,
    MapaRetoVerdeComponent,
    MapaCaptacionAguaComponent,
    MapaInfraSocialComponent,
    DashboardStackComponent,
    LineChartComponent,
    BarChartComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  entryComponents: [
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
    CourierComponent,
    NotFoundComponent,
    BarComponent,
    MapaColoniasComponent,
    StackbarComponent,
    PieChartComponent,
    AreaChartComponent,
    MapaAlcaldiasComponent,
    MapaSemujeresComponent,
    MapOpenDataComponent,
    MapControlComponent,
    MapaCaptacionAguaComponent,
    MapParkComponent,
    MapParkPointsComponent,
    MapaRetoVerdeComponent,
    MapaInfraSocialComponent,
    DashboardStackComponent,
    LineChartComponent,
    BarChartComponent
  ]
})
export class ComponentsModule { }
