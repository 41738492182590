import { Component } from '@angular/core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  faCoffee = faCoffee;
  title = 'renovacionesCDMX';

  ngOnInit() {
    let prefrence = window.matchMedia('(prefers-color-scheme: dark)').matches
    let themeOption = localStorage.getItem("theme-cdmx");
    let na = document.getElementById("covid19");

    if (themeOption) {
      if (themeOption === "true") {
        na.classList.add("dark");
      } else {
        na.classList.remove("dark");
      }
    } else {
      if (prefrence) {
        na.classList.add("dark");
      } else {
        na.classList.remove("dark");
      }
    }

  }
}
