import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';
import { PathResolveService } from './services/path-resolve.service';
// import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },{
    path: 'transparencia-covid',
    loadChildren: () => import('./pages/transparencia-covid/transparencia-covid.module').then(m => m.TransparenciaCovidPageModule)
  },{
    path: 'plan-apertura',
    loadChildren: () => import('./pages/plan-apertura/plan-apertura.module').then(m => m.PlanAperturaPageModule)
  },{
    path: 'movilidad',
    loadChildren: () => import('./pages/movilidad/movilidad.module').then(m => m.MovilidadPageModule)
  },{
    path: 'plan-accion',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },{
    path: 'carta',
    loadChildren: () => import('./pages/carta/carta.module').then(m => m.CartaPageModule)
  },{
    path: 'appcdmxprivacidad',
    loadChildren: () => import('./pages/aviso-privacidad/aviso-privacidad.module').then(m => m.AvisoPrivacidadPageModule)
  },{
    path: 'semujeres',
    loadChildren: () => import('./pages/semujeres/semujeres.module').then(m => m.SemujeresPageModule)
  },{
    path: 'alcaldias-recursos-humanos',
    loadChildren: () => import('./pages/alcaldias-recursos-humanos/alcaldias-recursos-humanos.module').then(m => m.AlcaldiasRecursosHumanosPageModule)
  },{
    path: 'alcaldias-presupuesto',
    loadChildren: () => import('./pages/alcaldias-presupuesto/alcaldias-presupuesto.module').then(m => m.AlcaldiasPresupuestoPageModule)
  },{
    path: 'recursos-federales',
    loadChildren: () => import('./pages/recursos-federales/recursos-federales.module').then(m => m.RecursosFederalesPageModule)
  },{
    path: 'parquimetros',
    loadChildren: () => import('./pages/parquimetros/parquimetros.module').then(m => m.ParquimetrosPageModule)
  },{
    path: 'reto-verde',
    loadChildren: () => import('./pages/reto-verde/reto-verde.module').then(m => m.RetoVerdePageModule)
  },{
    path: 'sistemas-captacion-agua',
    loadChildren: () => import('./pages/sistemas-captacion-agua/sistemas-captacion-agua.module').then(m => m.SistemasCaptacionAguaPageModule)
  },{
    path: 'infraestructura-social',
    loadChildren: () => import('./pages/infraestructura-social/infraestructura-social.module').then(m => m.InfraestructuraSocialPageModule)
  }

  /*,{
    path: '**',
    resolve: {
      path: PathResolveService
    },
    component: NotFoundComponent
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
