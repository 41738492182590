export const environment = {
  production: true,
  auth: {
    key: 'OauthIdentityServiceProvider.CDMX.LastUser'
  },
  api: {
    base_endpoint: 'https://sftys.cdmx.gob.mx/api/v1/',
    dynforms_endpoint: 'https://sftys.cdmx.gob.mx/api/v3/',
    opendata_endpoint: 'https://sftys.cdmx.gob.mx/api/v1/'
  },
  llave: {
    llave_url: "https://llave.cdmx.gob.mx/oauth.xhtml",
    token_url: "https://llave.cdmx.gob.mx/rest/oauth/token",
    client_id: "202008030906372582",
    secret_code: "-cibDC08IOtax26eUdB8cob63uBNnc",
    redirect_url: "https://empresaresponsable.covid19.cdmx.gob.mx/",
    username: "empresas_responsables",
    password: "3Empr35$4s%R3sP0nS4Bl13$sPr0d03Ago$t02020",
    tokenUP: "ZW1wcmVzYXNfcmVzcG9uc2FibGVzOjNFbXByMzUkNHMlUjNzUDBuUzRCbDEzJHNQcjBkMDNBZ28kdDAyMDIw"
  },
  base: {
    url: "https://datos.cdmx.gob.mx",
    sub_url: "https://paginas.datos.cdmx.gob.mx"
  }
};

